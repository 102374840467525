import { NgModule } from '@angular/core';
import { Routes, PreloadAllModules, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('./token/token.module').then((m) => m.TokenModule)
  },
  {
    path: 'part/creation',
    loadChildren: () =>
      import('./register-part/register-part.module').then((m) => m.RegisterPartModule)
  },
  {
    path: 'pro/creation',
    loadChildren: () =>
      import('./register-pro/register-pro.module').then((m) => m.RegisterProModule)
  },
  {
    path: 'lost-password',
    loadChildren: () =>
      import('./lost-password/lost-password.module').then((m) => m.LostPasswordModule)
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

