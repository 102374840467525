import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import {
  DialogLayoutDisplay,
  ToastNotificationInitializer,
  ToastPositionEnum,
  AppearanceAnimation,
  DisappearanceAnimation
} from '@costlydeveloper/ngx-awesome-popup';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
//import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  validateIMMOCLICKCookies: string;
  

/*   constructor(private ccService: NgcCookieConsentService, private router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
    this.router.events.pipe(
      filter((event:RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(event => {
      if (isPlatformBrowser(this.platformId)) {
        window.scroll(0, 0);
      }
    });
  } */


  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object, 
              private spinnerService: NgxSpinnerService) {
    
    this.router.events.pipe(
      filter((event:RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(event => {
      if (isPlatformBrowser(this.platformId)) {
        window.scroll(0, 0);
      }
    });
  }

  ngOnInit() {


    this.validateIMMOCLICKCookies = localStorage.getItem('validateIMMOCLICKCookies');
    if(this.validateIMMOCLICKCookies  == "non" || this.validateIMMOCLICKCookies  == null){
     
      this.cookieBanner(); // open confirm box

    }

  }

  // Create the method
  // Create the method
  cookieBanner() {
    const newCookieBanner = new ToastNotificationInitializer();

    newCookieBanner.setTitle('Salut, c\'est nous ! Les cookies 🍪🍪🍪');
    
    
    newCookieBanner.setMessage(
      'Nous utilisons des cookies pour proposer la meilleure expérience possible sur BEBIMMO, '
      +'pour fournir nos services, mais également à des fins de publicité et d\'analyse et pour personnaliser le contenu. '
      +'Pour accepter l\'utilisation des cookies, cliquez sur « Oui, bien sûr ».'
    );

    // Choose layout color type
    newCookieBanner.setConfig({
      textPosition: 'left', // optional
      buttonPosition: 'right', // optional
      layoutType: DialogLayoutDisplay.NONE, // SUCCESS | INFO | NONE | DANGER | WARNING
      toastPosition: ToastPositionEnum.BOTTOM_RIGHT, // TOP_LEFT | TOP_CENTER | TOP_RIGHT | TOP_FULL_WIDTH | BOTTOM_LEFT | BOTTOM_CENTER | BOTTOM_RIGHT | BOTTOM_FULL_WIDTH
      animationIn: AppearanceAnimation.SLIDE_IN_RIGHT,
      animationOut: DisappearanceAnimation.SLIDE_OUT_RIGHT
    });

    newCookieBanner.setButtonLabels('Oui, bien sûr','Non, merci');

    // Simply open the popup and observe button click
    newCookieBanner
      .openToastNotification$()
      .subscribe(resp => {
        if (resp.clickedButtonID) {
          console.log('Button clicked: ', resp.clickedButtonID);
          if (resp.success) {
            localStorage.setItem('validateIMMOCLICKCookies', 'oui');
          } else {
            localStorage.setItem('validateIMMOCLICKCookies', 'non');
          }
        }
      });
  }

  /* ngOnInit() {
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popupOpen');
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popuClose');
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`initialize: ${JSON.stringify(event)}`);
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`statusChange: ${JSON.stringify(event)}`);
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`revokeChoice`);
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`noCookieLaw: ${JSON.stringify(event)}`);
      });


    // (Optional) support for translated cookies messages
    
  } */

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}

