// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*

export const environment = {
  production: false,
  mailAdmin: 'mejdi.slim@yahoo.com',
  apiBaseUrl: 'https://bebimmo.com/',
  clientUrl: '?redirect_uri=https://bebimmo.com/login'
};


export const environment = {
  production: false,
  mailAdmin: 'mejdi.slim@yahoo.com',
  apiBaseUrl: 'https://immoclick-recette.fr/',
  clientUrl: '?redirect_uri=https://immoclick-recette.fr/login'
};

export const environment = {
  production: false,
  mailAdmin: 'mejdi.slim@yahoo.com',
  apiBaseUrl: 'http://localhost:2050/',
  clientUrl: '?redirect_uri=http://localhost:2050/login'
};



*/


export const environment = {
  production: false,
  mailAdmin: 'mejdi.slim@yahoo.com',
  apiBaseUrl: 'https://bebimmo.com/',
  clientUrl: '?redirect_uri=https://bebimmo.com/login'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
