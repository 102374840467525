<div>
    <router-outlet></router-outlet>
</div>







