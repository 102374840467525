import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
//import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DpeModule } from 'ng-bilan-energetique'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { OnlineStatusModule } from 'ngx-online-status';
import { MatPaginatorIntlCro } from './_custom/custom-paginator';
import { NgxSpinnerModule  } from "ngx-spinner";
import { NgxSpinnersModule } from 'ngx-spinners';
import { NgxAwesomePopupModule, ToastNotificationConfigModule } from '@costlydeveloper/ngx-awesome-popup';


/* const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "localhost"
  },
  "position": "bottom-right",
  "theme": "block",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000"
    }
  },
  "type": "info",
  "content": {
    "message": "Notre site internet utilise des cookies pour vous garantir la meilleure expérience possible sur notre site.",
    "dismiss": "Compris!",
    "deny": "Tout refuser",
    "link": "En apprendre plus",
    "href": "https://www.lesiteimmo.com/cgu-politique-de-confidentialite-cookies",
    "policy": "Cookie Policy"
  }
};
 */

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatListModule, 
    MatIconModule, 
    MatMenuModule, 
    MatSidenavModule, 
    MatToolbarModule, 
    DpeModule,
    NgxSpinnersModule,
    OnlineStatusModule,
    //NgcCookieConsentModule.forRoot(cookieConfig),
    CoreModule,
    SharedModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxAwesomePopupModule.forRoot(), // Essential, mandatory main module.
    ToastNotificationConfigModule.forRoot() // Essential, mandatory toast
  ],
  providers: [{ 
      provide: MatPaginatorIntl, 
      useValue: MatPaginatorIntlCro() 
    }
],
  bootstrap: [AppComponent]
})
export class AppModule {}
